import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';

import { Box, Button } from '@mui/material';
import cx from 'classnames';

export default function CountdownButton({
    text,
    endText = text,
    disabled = false,
    isAllowFistRenderClick = false,
    countDownSeconds,
    handleOnClick,
    isAllowOnClickReset = false,
    className,
    IconCountEnd
}) {
    const [isCountEnd, setIsCountEnd] = useState(false);
    const [countDownDate, setCountDownDate] = useState(
        Date.now() + countDownSeconds
    );
    const [isFirstClick, setIsFirstClick] = useState(true);

    useEffect(() => {
        isAllowFistRenderClick && setIsCountEnd(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAllowFistRenderClick]);

    const handleOnCountDownClick = () => {
        if (isFirstClick) {
            setIsFirstClick(false);
        }
        if (isAllowOnClickReset) {
            setIsCountEnd(false);
            setCountDownDate(Date.now() + countDownSeconds);
        }

        handleOnClick();
    };

    return (
        <Button
            className={cx([
                'btn',
                'btnCountDown',
                isCountEnd ? '' : 'countingBtnCountDown',
                className
            ])}
            variant="contained"
            color="primary"
            onClick={handleOnCountDownClick}
            disabled={!isCountEnd || disabled}>
            <Box className="content">
                {isCountEnd && IconCountEnd && (
                    <IconCountEnd className="icon iconAddition countEndIco" />
                )}
                <Box className="text">{isCountEnd ? text : endText}</Box>
            </Box>
            {!isCountEnd && (
                <Countdown
                    date={countDownDate}
                    renderer={({ seconds }) =>
                        seconds !== 0 && (
                            <span className="countDownSecondTxt">
                                ({seconds})
                            </span>
                        )
                    }
                    onComplete={() => {
                        setIsCountEnd(true);
                    }}
                />
            )}
        </Button>
    );
}
