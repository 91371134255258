import { useTranslation } from 'react-i18next';

import { Error } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import './Error.scss';

export default function ErrorPage() {
    const { t } = useTranslation();
    return (
        <Box className="error wrapper">
            <Box className="headline">
                <Error className="iconHead cancelIco" />
                <Typography component="h3">
                    {t('common.resultStatus.tradeError.title')}
                </Typography>
            </Box>
            <Typography className="message">
                {t('common.resultStatus.tradeError.message')}
            </Typography>
        </Box>
    );
}
