import {
    CURRENCIES_AVAILABLE_INFO,
    TRANSACTION_TYPE
} from '@constants/constants';
import { PAY_TYPE_INFO } from '@constants/payType';

export const isCurrencyValid = (targetCurrency) => {
    return (
        -1 !==
        Object.keys(CURRENCIES_AVAILABLE_INFO).findIndex((currencyKey) => {
            return (
                CURRENCIES_AVAILABLE_INFO[currencyKey].NAME === targetCurrency
            );
        })
    );
};

export const isTransactionTypeValid = (targetTransactionType) => {
    return (
        -1 !==
        Object.keys(TRANSACTION_TYPE).findIndex((transactionTypeKey) => {
            return (
                TRANSACTION_TYPE[transactionTypeKey] === targetTransactionType
            );
        })
    );
};

export const getPayType = (tradingData) => {
    return tradingData.alipayAccountNo
        ? PAY_TYPE_INFO.ALIPAY.NAME
        : PAY_TYPE_INFO.BANKPAY.NAME;
};
