import { CircleLoadingIco } from '@template/assets/images';
import cx from 'classnames';

import '@template/components/InfosPart/CircularProgress/CircularProgress.scss';

const CircularProgress = ({ classNames = [] }) => {
    return (
        <CircleLoadingIco
            size={14}
            className={cx(['icon circleLoadingIco', ...classNames])}
        />
    );
};

export default CircularProgress;
