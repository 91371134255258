import './Header.scss';

const Header = () => {
    return null;
    // const { isMobile } = useWindowSize();

    // return (
    //     <Box className="headerWrapper">
    //         <Box className="mainHeaderWrapper">
    //             <Box className="contentHeaderWrapper">
    //                 <LogoBrand />
    //                 {!isMobile && <CompanyInfos />}
    //             </Box>
    //         </Box>
    //         {isMobile && <CompanyInfos />}
    //     </Box>
    // );
};

export default Header;
