import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ItemNumericUnit from '@components/ItemFieldPart/ItemNumericUnit/ItemNumericUnit';

import {
    CURRENCIES_AVAILABLE_INFO,
    TRANSACTION_TYPE
} from '@constants/constants';

export default function useConfirmInfos() {
    const { t } = useTranslation();
    const { tradingData } = useSelector((state) => state.order);
    const { transactionType } = useParams();
    const infosTitle = t('common.confirmInfos.title', '');
    const { isShowBuySellPrices } = useSelector(
        (state) => state.displaySetting
    );

    const confirmInfos = [
        {
            fieldLabel: 'orderNo',
            fieldName: t('common.orderInfos.orderNo'),
            isValueBold: true,
            isShowCopyBtn: true
        },
        {
            fieldLabel: 'sellerName',
            fieldName:
                transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
                    ? t('common.orderInfos.sellerName')
                    : t('common.orderInfos.buyerName'),
            isValueBold: true,
            isShowCopyBtn: true
        },
        {
            fieldLabel: 'count',
            fieldName:
                transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
                    ? t('common.orderInfos.buyUnit')
                    : t('common.orderInfos.sellUnit'),
            renderFieldValue: () =>
                ItemNumericUnit({
                    numeric: tradingData.count,
                    currency: tradingData.currency,
                    isShowUnit: false
                }),
            isValueBold: true
        },
        ...(isShowBuySellPrices
            ? [
                  {
                      fieldLabel: 'userPrice sellUserPrice',
                      fieldName: t('common.orderInfos.sellUnitPrice'),
                      renderFieldValue: () =>
                          ItemNumericUnit({
                              numeric: tradingData.userPrice,
                              currency: tradingData.legalCurrency,
                              isShowUnit:
                                  tradingData.legalCurrency !==
                                  CURRENCIES_AVAILABLE_INFO.USDT.NAME
                          }),
                      isValueBold: true
                  },
                  {
                      fieldLabel: 'userPrice buyUserPrice',
                      fieldName: t('common.orderInfos.buyUnitPrice'),
                      renderFieldValue: () =>
                          ItemNumericUnit({
                              numeric: tradingData.userPrice,
                              currency: tradingData.legalCurrency,
                              isShowUnit:
                                  tradingData.legalCurrency !==
                                  CURRENCIES_AVAILABLE_INFO.USDT.NAME
                          }),
                      isValueBold: true
                  }
              ]
            : [
                  {
                      fieldLabel: 'userPrice',
                      fieldName: t('common.orderInfos.userPrice'),
                      renderFieldValue: () =>
                          ItemNumericUnit({
                              numeric: tradingData.userPrice,
                              currency: tradingData.legalCurrency,
                              isShowUnit:
                                  tradingData.legalCurrency !==
                                  CURRENCIES_AVAILABLE_INFO.USDT.NAME
                          }),
                      isValueBold: true
                  }
              ]),
        {
            fieldLabel: 'realAmount',
            fieldName:
                transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
                    ? t('buyProcess.realAmount')
                    : t('sellProcess.realAmount'),
            renderFieldValue: () =>
                ItemNumericUnit({
                    numeric: tradingData.realAmount,
                    currency: tradingData.legalCurrency,
                    isShowUnit:
                        tradingData.legalCurrency !==
                        CURRENCIES_AVAILABLE_INFO.USDT.NAME
                }),
            isValueBold: true
        }
    ];

    return {
        infosTitle,
        confirmInfos
    };
}
