import { Box } from '@mui/material';

import './RemarkMsgs.scss';

const RemarkMsgs = ({ remarkMsgs }) => {
    return (
        !!remarkMsgs && (
            <Box className="remarkMsgWrapper">
                {remarkMsgs.length > 1 ? (
                    remarkMsgs.map((remark, idx) => (
                        <Box
                            className="remarkMsg"
                            key={`remarkMsgs+${idx}`}>{`${
                            idx + 1
                        }. ${remark}`}</Box>
                    ))
                ) : (
                    <Box className="remarkMsg">{remarkMsgs[0]}</Box>
                )}
            </Box>
        )
    );
};

export default RemarkMsgs;
