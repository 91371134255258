import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { AmountInfos, InfosHeader } from '@components/_index';
import '@template/pages/Payment/TwoStepSimple/components/Resulted/Resulted.scss';

import { RESULT_STATUS } from '@constants/constants';
import useAddressInfos from '@hooks/infos/useAddressInfos';

import '@template/styles/common/pages/Resulted.scss';
import '@template/styles/common/pages/ResultedTwoStepSimple.scss';

export default function Resulted() {
    const { tradingData } = useSelector((state) => state.order);
    const { infosTitle, leftInfos, rightInfos, remarkMsgs } = useAddressInfos({
        isShowAddress: false,
        isHightLightUnitAmount: false,
        isShowRemarkMsgs: false
    });

    return (
        <Box className="wrapper resultedWrapper resultedWrapperTwoSimple">
            <Box className="mainInfosWrapper">
                <InfosHeader />
                {tradingData.status !== RESULT_STATUS.ORDER_ERROR && (
                    // <Box className="infosGroupWrapper infosGroupWrapperBeta">
                    <AmountInfos
                        infosTitle={infosTitle}
                        leftInfos={leftInfos}
                        rightInfos={rightInfos}
                        tradingData={tradingData}
                        remarkMsgs={remarkMsgs}
                        isShowTimerCancel={false}
                    />
                    // </Box>
                )}
            </Box>
        </Box>
    );
}
