import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Box, Button } from '@mui/material';
import qs from 'qs';

import { CircularProgress } from '@components/_index';

import { fetchOrderDetail } from '@template/store/order/slice';
import {
    setSmsCode,
    setSmsCodeVerifyMsg
} from '@template/store/verification/slice';

import API from '@api/index';
import { callAPI } from '@template/utils/apiCallback';

import { TickIco } from '@images';

const VerifySmsButton = ({ btnTxt, btnTxtVerifying }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { legalCurrency, transactionType } = useParams();
    const { smsCode, wholeMobile, areaCode } = useSelector(
        (state) => state.verification
    );
    const [isSmsCodeVerifying, setIsSmsCodeVerifying] = useState(false);

    const verifySmsCode = () => {
        const location = window.location;
        const queryUrl = qs.parse(location.search, { ignoreQueryPrefix: true });
        const { token, orderNo } = queryUrl;

        const payload = {
            token,
            orderNo,
            code: smsCode
        };
        dispatch(setSmsCodeVerifyMsg(''));
        setIsSmsCodeVerifying(true);

        callAPI({
            apiCall: () => API.order.smsCodeVerify(payload),
            successCallback: () => {
                dispatch(setSmsCode(undefined));
                dispatch(fetchOrderDetail(legalCurrency, transactionType));
                return;
            },
            failedCallBack: () => {
                dispatch(
                    setSmsCodeVerifyMsg(
                        t('common.smsVerificationInfos.smsCodeVerifyFailedMsg')
                    )
                );
            },
            errCallBack: () => {
                dispatch(
                    setSmsCodeVerifyMsg(
                        t('common.smsVerificationInfos.smsCodeVerifyFailedMsg')
                    )
                );
            },
            finalCallBack: () => {
                setIsSmsCodeVerifying(false);
            }
        });
    };

    const isOnlyAreaCode = areaCode.length >= wholeMobile.length;

    return (
        <Button
            className="btn btnConfirm"
            variant="contained"
            color="primary"
            disabled={
                !smsCode ||
                !wholeMobile ||
                !areaCode ||
                isOnlyAreaCode ||
                isSmsCodeVerifying
            }
            onClick={() => verifySmsCode()}>
            {isSmsCodeVerifying && <CircularProgress />}
            <Box className="content">
                <TickIco className="icon iconAddition iconTick" />
                <Box className="text">
                    {isSmsCodeVerifying ? btnTxtVerifying : btnTxt}
                </Box>
            </Box>
        </Button>
    );
};
export default VerifySmsButton;
