import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { CopyButton, ItemTag, QRCodeBoard } from '@components/_index';
import { InfosLayout } from '@layouts/_index';
import '@template/components/InfosForm/QRBoardInfos/QRBoardInfos.scss';

import '@styles/common/QRBoardInfos.scss';

export default function QRBoardInfos() {
    const {
        tradingData: { toAddress, chainName }
    } = useSelector((state) => state.order);
    return (
        <InfosLayout className="qrBoardInfosWrapper">
            <QRCodeBoard fieldValue={toAddress} />
            <Box className="chipGroupWrapper">
                <Box className="itemTagWrapper chainNameWrapper">
                    <ItemTag
                        tagName={chainName === 'ETH_USDT' ? 'ERC-20' : 'TRC-20'}
                        label={chainName === 'ETH_USDT' ? 'ercTag' : 'trcTag'}
                        isShowIcon={false}
                    />
                </Box>
                <Box className="itemTagWrapper addressWrapper">
                    <ItemTag
                        tagName={toAddress}
                        label={chainName === 'ETH_USDT' ? 'ercTag' : 'trcTag'}
                        isShowIcon={false}
                    />
                    <CopyButton copyValue={toAddress} />
                </Box>
            </Box>
        </InfosLayout>
    );
}
