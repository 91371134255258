import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ItemNumericUnit from '@components/ItemFieldPart/ItemNumericUnit/ItemNumericUnit';

import {
    CURRENCIES_AVAILABLE_INFO,
    TRANSACTION_TYPE
} from '@constants/constants';

export default function useAmountInfos() {
    const { t } = useTranslation();
    const { transactionType } = useParams();
    const { tradingData } = useSelector((state) => state.order);
    const { isShowBuySellPrices } = useSelector(
        (state) => state.displaySetting
    );
    const infosTitle =
        transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
            ? t('common.amountInfos.buyTitle')
            : t('common.amountInfos.sellTitle');

    let leftInfos = [
        {
            fieldLabel: 'count',
            fieldName:
                transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
                    ? t('common.orderInfos.buyUnit')
                    : t('common.orderInfos.sellUnit'),
            renderFieldValue: () =>
                ItemNumericUnit({
                    numeric: tradingData.count,
                    currency: tradingData.currency,
                    isShowUnit: false
                }),
            isValueBold: true
        },
        ...(isShowBuySellPrices
            ? [
                  {
                      fieldLabel: 'userPrice sellUserPrice',
                      fieldName: t('common.orderInfos.sellUnitPrice'),
                      renderFieldValue: () =>
                          ItemNumericUnit({
                              numeric: tradingData.userPrice,
                              currency: tradingData.legalCurrency,
                              isShowUnit:
                                  tradingData.legalCurrency !==
                                  CURRENCIES_AVAILABLE_INFO.USDT.NAME
                          }),
                      isValueBold: true
                  },
                  {
                      fieldLabel: 'userPrice buyUserPrice',
                      fieldName: t('common.orderInfos.buyUnitPrice'),
                      renderFieldValue: () =>
                          ItemNumericUnit({
                              numeric: tradingData.userPrice,
                              currency: tradingData.legalCurrency,
                              isShowUnit:
                                  tradingData.legalCurrency !==
                                  CURRENCIES_AVAILABLE_INFO.USDT.NAME
                          }),
                      isValueBold: true
                  }
              ]
            : [
                  {
                      fieldLabel: 'userPrice',
                      fieldName: t('common.orderInfos.userPrice'),
                      renderFieldValue: () =>
                          ItemNumericUnit({
                              numeric: tradingData.userPrice,
                              currency: tradingData.legalCurrency,
                              isShowUnit:
                                  tradingData.legalCurrency !==
                                  CURRENCIES_AVAILABLE_INFO.USDT.NAME
                          }),
                      isValueBold: true
                  }
              ])
    ];

    const rightInfos = [
        {
            fieldLabel: 'realAmount',
            fieldName:
                transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
                    ? t('buyProcess.realAmount')
                    : t('sellProcess.realAmount'),
            fieldValue:
                tradingData.legalCurrency + ' ' + tradingData.realAmount,
            renderFieldValue: () =>
                ItemNumericUnit({
                    numeric: tradingData.realAmount,
                    currency: tradingData.legalCurrency,
                    isShowUnit:
                        tradingData.legalCurrency !==
                        CURRENCIES_AVAILABLE_INFO.USDT.NAME
                }),
            isShowCopyBtn: true
        }
    ];

    const amountInfosRemarkMsgs = [
        `${t('buyProcess.paying.noticeMsgSafety1')}  ${t('common.product')} ${t(
            'buyProcess.paying.noticeMsgSafety2'
        )}`
    ];

    const totalAmountInfosRemarkMsgs = [t('buyProcess.paying.noticeMsgAmount')];

    const remarkMsgs = [
        ...amountInfosRemarkMsgs,
        ...totalAmountInfosRemarkMsgs
    ];

    return {
        tradingData,
        infosTitle,
        leftInfos,
        rightInfos,
        amountInfosRemarkMsgs,
        totalAmountInfosRemarkMsgs,
        remarkMsgs
    };
}
