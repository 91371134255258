import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { CountdownButton } from '@components/_index';

import { confirmOrder } from '@store/order/slice';

import { TickIco } from '@images';

const ConfirmButtonHold = ({ btnTxt }) => {
    const dispatch = useDispatch();
    const { legalCurrency, transactionType } = useParams();

    return (
        <CountdownButton
            className="btnConfirm"
            text={btnTxt}
            countDownSeconds={15000}
            IconCountEnd={TickIco}
            handleOnClick={() =>
                dispatch(confirmOrder(legalCurrency, transactionType))
            }
        />
    );
};
export default ConfirmButtonHold;
