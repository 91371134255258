import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Button, Typography } from '@mui/material';

import { setReceiptImage } from '@store/order/slice';

import '@template/components/InfosPart/ReceiptUpload/ReceiptUpload.scss';

const ReceiptUpload = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [fileName, setFileName] = useState(
        t('common.paymentMethod.bankpay.noFileUpload')
    );

    return (
        <Box className="receiptUploadWrapper">
            <input
                id="receiptUpload"
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                onChange={(event) => {
                    dispatch(setReceiptImage(event.target.files[0]));
                    setFileName(event.target.files[0].name);
                }}
            />
            <label htmlFor="receiptUpload">
                <Button
                    variant="contained"
                    component="span"
                    className="btn uploadBtn">
                    {t('common.button.upload')}
                </Button>
            </label>
            <Typography>{fileName}</Typography>
        </Box>
    );
};

export default ReceiptUpload;
