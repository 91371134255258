import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RedirectButton } from '@components/_index';

import { ArrowRightIco } from '@images/';

const ReturnButton = () => {
    const { t } = useTranslation();
    const { tradingData } = useSelector((state) => state.order);

    return (
        <RedirectButton
            redirectUrl={tradingData.redirectUrl}
            text={t('common.button.backHome')}
            IconRedirect={ArrowRightIco}
        />
    );
};
export default ReturnButton;
