import { lazy } from 'react';

// ThreeStepsOnHoldConfirming steps
export { default as BuyPaying } from '@template/pages/Payment/ThreeStepsOnHoldConfirming/BuyProcess/BuyPaying/BuyPaying';
export { default as BuyConfirming } from '@template/pages/Payment/ThreeStepsOnHoldConfirming/BuyProcess/BuyConfirming/BuyConfirming';
export { default as SellPayingThreeStepsOnHoldConfirming } from '@template/pages/Payment/ThreeStepsOnHoldConfirming/SellProcess/SellPaying/SellPaying';
export { default as ResultedThreeStepsOnHoldConfirming } from '@template/pages/Payment/ThreeStepsOnHoldConfirming/components/Resulted/Resulted';

// TwoStepsSimple steps
export { default as BuyPayingManual } from '@template/pages/Payment/TwoStepSimple/BuyProcess/BuyPayingManual/BuyPayingManual';
export { default as SellPayingTwoStepsSimple } from '@template/pages/Payment/TwoStepSimple/SellProcess/SellPaying/SellPaying';
export { default as ResultedTwoStepsSimple } from '@template/pages/Payment/TwoStepSimple/components/Resulted/Resulted';

const LazyPages = {
    Payment: lazy(() => import('@pages/Payment/Payment')),
    USDTBuy: lazy(() =>
        import('@pages/Payment/TwoStepSimple/BuyProcess/BuyProcess')
    ),
    USDTSell: lazy(() =>
        import('@pages/Payment/TwoStepSimple/SellProcess/SellProcess')
    ),
    VNDBuy: lazy(() =>
        import(
            '@pages/Payment/ThreeStepsOnHoldConfirming/BuyProcess/BuyProcess'
        )
    ),
    VNDSell: lazy(() =>
        import(
            '@pages/Payment/ThreeStepsOnHoldConfirming/SellProcess/SellProcess'
        )
    ),

    THBBuy: lazy(() =>
        import(
            '@pages/Payment/ThreeStepsOnHoldConfirming/BuyProcess/BuyProcess'
        )
    ),
    THBSell: lazy(() =>
        import(
            '@pages/Payment/ThreeStepsOnHoldConfirming/SellProcess/SellProcess'
        )
    ),

    CNYBuy: lazy(() =>
        import(
            '@pages/Payment/ThreeStepsOnHoldConfirming/BuyProcess/BuyProcess'
        )
    ),
    CNYSell: lazy(() =>
        import(
            '@pages/Payment/ThreeStepsOnHoldConfirming/SellProcess/SellProcess'
        )
    ),
    ResolvePayment: lazy(() => import('@pages/ResolvePayment/ResolvePayment')),
    Error: lazy(() => import('@pages/Error/Error'))
};

export default LazyPages;
