import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import cx from 'classnames';

import { SPACING } from '@constants/styleLayout';

import '@styles/common/ItemField.scss';

import { CopyButton, ItemTagGroup } from '@components/_index';
import '@template/components/ItemField/ItemField.scss';

export default function ItemField({
    fieldLabel,
    fieldName,
    isShowCopyBtn, // to copy fieldValue
    fieldValue,
    renderFieldValue,
    isValueBold,
    isLabelBold,
    tagNames,
    children
}) {
    const { i18n } = useTranslation();
    const { itemFieldLeftFlexLayout } = useSelector(
        (state) => state.displaySetting
    );

    const fieldLabelWrapperMinWidth =
        i18n.language === 'zh-CN' ? SPACING.SMALL : SPACING.LARGE;

    return (
        <Box
            className={cx([
                'itemField',
                fieldLabel,
                { fieldLabelBold: isLabelBold }
            ])}>
            <Box
                className={cx([
                    'itemFieldLeft',
                    `${itemFieldLeftFlexLayout}ItemFieldLeft`
                ])}>
                <Box
                    className={cx([
                        'fieldLabelWrapper',
                        `${fieldLabelWrapperMinWidth}FieldLabelWrapperSpacing`
                    ])}>
                    <Box className="fieldLabel">{fieldName}</Box>
                    <Box className="fieldSeparate">{' :'}</Box>
                </Box>

                <Box className="fieldValueWrapper">
                    <Box
                        className={cx([
                            'fieldValue',
                            { fieldValueBold: isValueBold }
                        ])}>
                        <Box className="fieldValueTxt">
                            {!!renderFieldValue ? (
                                renderFieldValue()
                            ) : (
                                <span>{fieldValue ?? '---'}</span>
                            )}
                        </Box>
                        {isShowCopyBtn && fieldValue && (
                            <CopyButton copyValue={fieldValue} />
                        )}
                    </Box>
                    <Box className="fieldExtra">
                        {tagNames && <ItemTagGroup tagNames={tagNames} />}
                    </Box>
                </Box>

                {children}
            </Box>
        </Box>
    );
}
