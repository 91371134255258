import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';
import {
    CancelIco,
    ErrorIco,
    PendingIco,
    SuccessIco
} from '@template/assets/images';

import { FullPageLoading } from '@components/_index';
import '@template/components/InfosPart/InfosHeader/InfosHeader.scss';

import { RESULT_STATUS } from '@constants/constants';

function InfosHeader() {
    const { t } = useTranslation();
    const { tradingData } = useSelector((state) => state.order);
    const { status } = tradingData;
    const HEADER_INFO = {
        [RESULT_STATUS.ORDER_FINISH]: {
            iconComponent: <SuccessIco className="iconHead tickIco" />,
            title: t('common.resultStatus.completed.title'),
            message: t('common.resultStatus.completed.message')
        },
        [RESULT_STATUS.HANDLE_FINISH]: {
            iconComponent: <SuccessIco className="iconHead tickIco" />,
            title: t('common.resultStatus.completed.title'),
            message: t('common.resultStatus.completed.message')
        },
        [RESULT_STATUS.ORDER_CANCEL]: {
            iconComponent: <CancelIco className="iconHead cancelIco" />,
            title: t('common.resultStatus.tradeCancelled.title'),
            message: t('common.resultStatus.tradeCancelled.message')
        },
        [RESULT_STATUS.PROCESSING]: {
            iconComponent: <SuccessIco className="iconHead processIco" />,
            title: t('common.resultStatus.processed.title'),
            message: t('common.resultStatus.processed.message')
        },
        [RESULT_STATUS.PAY_FINISH]: {
            iconComponent: <PendingIco className="iconHead waitingIco" />,
            title: t('common.resultStatus.confirming.title'),
            message:
                t('common.resultStatus.confirming.message1') +
                ' ' +
                t('common.resultStatus.confirming.message2')
        },
        [RESULT_STATUS.ORDER_ERROR]: {
            iconComponent: <ErrorIco className="iconHead errorIco" />,
            title: t('common.resultStatus.tradeError.title'),
            message: t('common.resultStatus.tradeError.message')
        }
    };

    const isValidStateShowHeader =
        -1 !==
        Object.keys(HEADER_INFO).findIndex((headerInfoStatus) => {
            return status === headerInfoStatus;
        });

    if (!isValidStateShowHeader) {
        return <FullPageLoading />;
    }

    // For overlay loading
    // useEffect(() => {
    //     const isValidStateShowHeader =
    //         -1 !==
    //         Object.keys(HEADER_INFO).findIndex((headerInfoStatus) => {
    //             return status === headerInfoStatus;
    //         });
    //     // should be !isValidStateShowHeader
    //     dispatch(setIsShowLoading(!isValidStateShowHeader));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [status]);

    return (
        <Box className="infosHeader">
            <Box className="headline">
                {HEADER_INFO[status].iconComponent}
                <Typography className="title">
                    {HEADER_INFO[status].title}
                </Typography>
            </Box>
            <Typography className="message">
                {HEADER_INFO[status].message}
            </Typography>
        </Box>
    );
}

export default InfosHeader;
