import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { FullPageLoading } from '@components/_index';
import Pages from '@template/pages/_index';

import { RESULT_STATUS } from '@constants/constants';
import { fetchOrderDetail } from '@store/order/slice';

import { isCurrencyValid, isTransactionTypeValid } from '@utils/utils';

export default function ResolvePayment() {
    const dispatch = useDispatch();
    const { legalCurrency, transactionType } = useParams();
    const { isUrlParamsMatchApi, resultStatus } = useSelector(
        (state) => state.order
    );
    useEffect(() => {
        dispatch(fetchOrderDetail(legalCurrency, transactionType));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [legalCurrency, transactionType]);

    if (!resultStatus) {
        return <FullPageLoading />;
    }

    // For overlay loading
    // useEffect(() => {
    //     // should be !resultStatus
    //     dispatch(setIsShowLoading(!resultStatus));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [resultStatus]);

    if (
        resultStatus === RESULT_STATUS.ORDER_ERROR ||
        !isUrlParamsMatchApi ||
        !isCurrencyValid(legalCurrency.toUpperCase()) ||
        !isTransactionTypeValid(transactionType.toUpperCase())
    ) {
        return <Pages.Error />;
    } else {
        return <Pages.Payment />;
    }
}
