import { Box } from '@mui/material';

import { ItemFieldList, RemarkMsgs, TimerCancel } from '@components/_index';
import { InfosLayout } from '@layouts/_index';
import '@template/components/InfosForm/RecipientInfos/RecipientInfos.scss';

import { TRANSACTION_TYPE } from '@constants/constants';

import useRecipientInfos from './hook';

export default function RecipientInfos() {
    const {
        tradingData,
        transactionType,
        infosTitle,
        traderInfos,
        accountInfos,
        remarkMsgs
    } = useRecipientInfos();

    return (
        <InfosLayout className="recipientInfos" infosTitle={infosTitle}>
            <Box className="infosWrapper">
                <Box className="mainInfosItemsWrapper">
                    <Box className="leftInfos">
                        <Box className="leftAlphaInfosWrapper">
                            <ItemFieldList
                                listInfos={traderInfos}
                                fieldData={tradingData}
                            />
                            <TimerCancel />
                        </Box>
                        <Box className="leftBetaInfosWrapper">
                            <ItemFieldList
                                className="accountInfos"
                                listInfos={accountInfos}
                                fieldData={tradingData}
                            />
                        </Box>
                    </Box>
                    {transactionType.toUpperCase() === TRANSACTION_TYPE.BUY && (
                        <RemarkMsgs remarkMsgs={remarkMsgs} />
                    )}
                </Box>
            </Box>
        </InfosLayout>
    );
}
