import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';
import { CopyIco } from '@template/assets/images';
import cx from 'classnames';

import '@template/components/InfosPart/Buttons/CopyButton/CopyButton.scss';

import useOpenSnackbar from '@hooks/app/useOpenSnackbar';

import '@styles/common/InfosPart/Buttons/CopyButton.scss';

export default function CopyButton({ copyValue }) {
    const { t, i18n } = useTranslation();

    const { openSnackbar } = useOpenSnackbar();

    const txtCopySize =
        i18n.language === 'vi' || i18n.language === 'th' ? 'large' : 'medium';

    return (
        <Box className="btnCopyWrapper">
            <CopyToClipboard
                text={copyValue}
                onCopy={() => {
                    openSnackbar(t('common.copied'));
                }}>
                <Button
                    className={cx([
                        'btn',
                        'btnCopy',
                        'btnBeta',
                        `${txtCopySize}BtnCopy`
                    ])}
                    variant="text"
                    color="primary">
                    <span className="txtCopy">{t('common.button.copy')}</span>
                    <CopyIco className="iconCopy" />
                </Button>
            </CopyToClipboard>
        </Box>
    );
}
