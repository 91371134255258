import { Box } from '@mui/material';

import { ItemFieldList, RemarkMsgs, TimerCancel } from '@components/_index';
import { InfosLayout } from '@layouts/_index';
import '@template/components/InfosForm/AmountInfos/AmountInfos.scss';

import '@styles/common/InfosForm/AmountInfos.scss';

export default function AmountInfos({
    infosTitle,
    leftInfos,
    rightInfos,
    tradingData,
    remarkMsgs,
    isShowTimerCancel = false
}) {
    return (
        <InfosLayout infosTitle={infosTitle} className="amountInfosWrapper">
            <Box className="infosWrapper">
                <Box className="mainInfosItemsWrapper">
                    <Box className="leftInfos">
                        <ItemFieldList
                            listInfos={leftInfos}
                            fieldData={tradingData}
                        />
                        {isShowTimerCancel && <TimerCancel />}
                    </Box>
                    <Box className="rightInfos">
                        <ItemFieldList
                            listInfos={rightInfos}
                            fieldData={tradingData}
                        />
                    </Box>
                </Box>
                <RemarkMsgs remarkMsgs={remarkMsgs} />
            </Box>
        </InfosLayout>
    );
}
