import { Box, Button } from '@mui/material';

export default function RedirectButton({ redirectUrl, text, IconRedirect }) {
    return (
        <Button
            className="btn btnRedirect"
            variant="contained"
            color="primary"
            href={redirectUrl}>
            <Box className="content">
                <IconRedirect className="icon iconAddition RedirectIco" />
                <Box className="text">{text}</Box>
            </Box>
        </Button>
    );
}
