import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';

import '@styles/common/AppDialog.scss';

import '@template/layouts/AppDialog/AppDialog.scss';

const AppDialog = ({
    name,
    isShowCloseButton = false,
    children,
    title,
    closeDialog,
    ...props
}) => {
    return (
        <Dialog
            className={`dialogWrapper ${name}`}
            onClose={closeDialog}
            {...props}>
            {isShowCloseButton && (
                <Box className="dialogBtnCloseWrapper">
                    <IconButton
                        className="btn btnClose"
                        onClick={closeDialog}
                        size="large">
                        <Close />
                    </IconButton>
                </Box>
            )}
            <Box className="DialogInfo">
                {title && (
                    <DialogTitle className="dialogTitle">{title}</DialogTitle>
                )}
                {children}
            </Box>
        </Dialog>
    );
};

export default AppDialog;
