import { Box } from '@mui/material';
import cx from 'classnames';

import { ItemField } from '@components/_index';

const ItemFieldList = ({ listInfos, fieldData, className }) => {
    return (
        <Box className={cx('itemFieldList', className)}>
            {listInfos.map(({ fieldValue, ...props }) => {
                return (
                    <ItemField
                        key={props.fieldLabel}
                        {...props}
                        fieldValue={fieldValue ?? fieldData[props.fieldLabel]}
                    />
                );
            })}
        </Box>
    );
};

export default ItemFieldList;
