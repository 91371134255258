import { useEffect, useState } from 'react';

export function useStaleFree(callback) {
    const [call, setCall] = useState(false);
    useEffect(() => {
        if (call) {
            callback();
            setCall(false);
        }
    }, [call, callback, setCall]);
    return () => setCall(true);
}
