import { createSlice } from '@reduxjs/toolkit';

import { paletteCustomize } from '@template/styles/palette';

const paletteSlice = createSlice({
    name: 'palette',
    initialState: paletteCustomize
});

export default paletteSlice;

// export const {} = paletteSlice.actions;
