import axiosConfig from './axiosConfig';

const orderAPI = {
    orderDetail(data) {
        return axiosConfig.get(`/order/${data.orderNo}/get`, data.token);
    },

    orderCancel(data) {
        return axiosConfig.post('/order/cancel', data);
    },

    orderConfirm(data) {
        return axiosConfig.post('/order/confirm', data);
    },

    smsCodeSend(data) {
        return axiosConfig.post('/order/sms/send', data);
    },

    smsCodeVerify(data) {
        return axiosConfig.post('/order/sms/verify', data);
    },

    kycVerify(data) {
        return axiosConfig.post('/order/kyc/verify', data);
    }
};

export default orderAPI;
