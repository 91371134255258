import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { ConfirmInfos, InfosHeader } from '@components/_index';
import '@template/pages/Payment/ThreeStepsOnHoldConfirming/components/Resulted/Resulted.scss';

import { RESULT_STATUS } from '@constants/constants';

import '@template/styles/common/pages/Resulted.scss';

export default function Resulted() {
    const { tradingData } = useSelector((state) => state.order);
    return (
        <Box className="wrapper resultedWrapper resultedWrapperThreeHoldConfirming">
            <Box className="mainInfosWrapper">
                <InfosHeader />
                {tradingData.status !== RESULT_STATUS.ORDER_ERROR && (
                    <ConfirmInfos />
                )}
            </Box>
        </Box>
    );
}
