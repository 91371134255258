import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';

import { RESULT_STATUS } from '@constants/constants';

import API from '@api/index';
import { callAPI } from '@template/utils/apiCallback';

const initialOrderState = {
    tradingData: {},
    resultStatus: null,
    isUrlParamsMatchApi: true,
    isShowCancelTimer: false,
    isCancelTimerFinished: false,
    receiptImage: null
};

const orderSlice = createSlice({
    name: 'order',
    initialState: {
        ...initialOrderState
    },
    reducers: {
        setTradingData: (state, action) => {
            state.tradingData = action.payload;
        },
        setResultStatus: (state, action) => {
            state.resultStatus = action.payload;
        },
        setIsUrlParamsMatchApi: (state, action) => {
            state.isUrlParamsMatchApi = action.payload;
        },
        setIsShowCancelTimer: (state, action) => {
            state.isShowCancelTimer = action.payload;
        },
        setIsCancelTimerFinished: (state, action) => {
            state.isCancelTimerFinished = action.payload;
        },
        setReceiptImage: (state, action) => {
            state.receiptImage = action.payload;
        }
    }
});

export default orderSlice;

export const {
    setResultStatus,
    setTradingData,
    setIsUrlParamsMatchApi,
    setIsShowCancelTimer,
    setIsCancelTimerFinished,
    setReceiptImage
} = orderSlice.actions;

export const fetchOrderDetail =
    (legalCurrency, transactionType) => async (dispatch, getState) => {
        const location = window.location;
        const queryUrl = qs.parse(location.search, { ignoreQueryPrefix: true });
        const { token, orderNo } = queryUrl;
        const { resultStatus } = getState().order;

        const orderData = {
            token: {
                params: { token }
            },
            orderNo
        };

        // API.order
        //     .orderDetail(orderData)
        //     .then((res) => {
        //         const resData = res.data.data;
        //         if (
        //             resData === null ||
        //             resData.legalCurrency !== legalCurrency.toUpperCase() ||
        //             resData.side !== transactionType.toUpperCase()
        //         ) {
        //             dispatch(setIsUrlParamsMatchApi(false));
        //             dispatch(setResultStatus(RESULT_STATUS.ORDER_ERROR));
        //             return;
        //         }

        //         if (res.data.code === 0) {
        //             if (resData.status !== resultStatus) {
        //                 dispatch(setIsUrlParamsMatchApi(true));
        //                 dispatch(setResultStatus(resData.status));
        //             }
        //             dispatch(setTradingData(resData));
        //         } else {
        //             dispatch(setResultStatus(RESULT_STATUS.ORDER_ERROR));
        //             return;
        //         }
        //     })
        //     .catch((error) => {
        //         dispatch(setResultStatus(RESULT_STATUS.ORDER_ERROR));
        //         return;
        //     });

        callAPI({
            apiCall: () => API.order.orderDetail(orderData),
            successCallback: (res) => {
                const resData = res.data.data;
                if (
                    resData === null ||
                    resData.legalCurrency !== legalCurrency.toUpperCase() ||
                    resData.side !== transactionType.toUpperCase()
                ) {
                    dispatch(setIsUrlParamsMatchApi(false));
                    dispatch(setResultStatus(RESULT_STATUS.ORDER_ERROR));
                    return;
                }

                if (resData.status !== resultStatus) {
                    dispatch(setIsUrlParamsMatchApi(true));
                    dispatch(setResultStatus(resData.status));
                }
                dispatch(setTradingData(resData));
            },
            failedCallBack: () => setResultStatus(RESULT_STATUS.ORDER_ERROR),
            errCallBack: () => {
                dispatch(setResultStatus(RESULT_STATUS.ORDER_ERROR));
                return;
            }
        });
    };

export const cancelOrder =
    (legalCurrency, transactionType) => async (dispatch) => {
        const location = window.location;
        const queryUrl = qs.parse(location.search, { ignoreQueryPrefix: true });
        const { token, orderNo } = queryUrl;

        const payload = {
            token,
            orderNo
        };

        callAPI({
            apiCall: () => API.order.orderCancel(payload),
            successCallback: () => {
                setResultStatus(RESULT_STATUS.ORDER_CANCEL);
                dispatch(fetchOrderDetail(legalCurrency, transactionType));
                return;
            },
            // failedCallBack: () => setResultStatus(RESULT_STATUS.ORDER_ERROR),
            errCallBack: () => setResultStatus(RESULT_STATUS.ORDER_ERROR)
        });
    };

export const confirmOrder =
    (legalCurrency, transactionType) => async (dispatch, getState) => {
        const location = window.location;
        const queryUrl = qs.parse(location.search, { ignoreQueryPrefix: true });
        const { token, orderNo } = queryUrl;
        const { receiptImage } = getState().order;

        let formData = new FormData();
        formData.append('token', token);
        formData.append('orderNo', orderNo);
        if (receiptImage !== null) {
            formData.append('receipt', receiptImage);
        }

        callAPI({
            apiCall: () => API.order.orderConfirm(formData),
            successCallback: () => {
                setResultStatus(RESULT_STATUS.ORDER_FINISH);
                dispatch(fetchOrderDetail(legalCurrency, transactionType));
                return;
            },
            // failedCallBack: () => setResultStatus(RESULT_STATUS.ORDER_ERROR),
            errCallBack: () => setResultStatus(RESULT_STATUS.ORDER_ERROR)
        });
    };
