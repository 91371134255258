import { useDialog } from '@utils/Providers/DialogProvider';

const useCloseDialog = (closeCallBack) => {
    // eslint-disable-next-line
    const [createDialog, closeDialog] = useDialog();

    return {
        closeDialog: () => {
            closeCallBack && closeCallBack();
            closeDialog();
        }
    };
};

export default useCloseDialog;
