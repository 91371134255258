import { Box } from '@mui/material';

import {
    AmountInfos,
    KycVerificationInfos,
    RecipientInfos,
    SmsVerificationInfos
} from '@components/_index';

import useAmountInfos from '@hooks/infos/useAmountInfos';

import '@template/styles/common/Paying.scss';

export default function BuyPaying() {
    const { infosTitle, leftInfos, rightInfos, tradingData, remarkMsgs } =
        useAmountInfos();
    return (
        <Box className="wrapper buyPaying">
            <Box className="mainInfosWrapper">
                <Box className="infosGroupWrapper">
                    <AmountInfos
                        infosTitle={infosTitle}
                        leftInfos={leftInfos}
                        rightInfos={rightInfos}
                        tradingData={tradingData}
                        remarkMsgs={remarkMsgs}
                        isShowTimerCancel={false}
                    />
                    {/* <CompanyInfos /> */}
                </Box>
                {tradingData.needSmsVerify && tradingData.verifyStatus === 0 ? (
                    <SmsVerificationInfos />
                ) : tradingData.needSelfInput &&
                  tradingData.verifyStatus === 0 ? (
                    <KycVerificationInfos />
                ) : (
                    <RecipientInfos />
                )}
            </Box>
        </Box>
    );
}
