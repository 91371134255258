import { Box } from '@mui/material';

import { ItemTag } from '@components/_index';

export default function ItemTagGroup({ tagNames }) {
    return (
        <Box className="itemTags">
            {tagNames.map((tagName) => {
                return <ItemTag key={tagName} tagName={tagName} />;
            })}
        </Box>
    );
}
