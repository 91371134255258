import { initReactI18next } from 'react-i18next';

import translationSpecificEN from '@template/assets/locales/en.json';
import translationSpecificSC from '@template/assets/locales/sc.json';
import translationSpecificTH from '@template/assets/locales/th.json';
import translationSpecificVI from '@template/assets/locales/vi.json';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { merge } from 'lodash';

import translationEN from '@locales/en.json';
import translationSC from '@locales/sc.json';
import translationTH from '@locales/th.json';
import translationVI from '@locales/vi.json';

const resources = {
    'en-US': {
        translation: merge(translationEN, translationSpecificEN)
    },
    'zh-CN': {
        translation: merge(translationSC, translationSpecificSC)
    },
    vi: {
        translation: merge(translationVI, translationSpecificVI)
    },
    th: {
        translation: merge(translationTH, translationSpecificTH)
    }
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            resources,
            detection: {
                order: ['querystring', 'navigator'],
                lookupQuerystring: 'language'
            },
            fallbackLng: 'en-US',
            interpolation: {
                escapeValue: false // react already safes from xss
            },
            react: {
                useSuspense: false
            }
        },
        (err, t) => {
            document.title = `${t('title', 'Payment Gateway')} - ${
                process.env.BRAND_NAME_EN
            }`;
        }
    );

export default i18n;
