import { useStaleFree } from '@hooks/app/useStaleFree';

import { useDialog } from '@utils/Providers/DialogProvider';

const useOpenDialog = ({ title, name, children }) => {
    const [createDialog, closeDialog] = useDialog();

    const openDialogWithStale = () => {
        createDialog({
            title,
            name,
            children
        });
    };

    const openDialog = useStaleFree(() => openDialogWithStale());

    return {
        openDialog,
        closeDialog
    };
};

export default useOpenDialog;
