import { Box } from '@mui/material';

import { ItemFieldList, RemarkMsgs, TimerCancel } from '@components/_index';
import { InfosLayout } from '@layouts/_index';
import '@template/components/InfosForm/SmsVerificationInfos/SmsVerificationInfos.scss';

import '@styles/common/InfosForm/SmsVerificationInfos.scss';

import useSmsVerificationInfos from './hook';

export default function SmsVerificationInfos() {
    const { tradingData, smsVerificationInfos, remarkMsg } =
        useSmsVerificationInfos();

    return (
        <InfosLayout className="smsVerificationInfosWrapper">
            <Box className="infosWrapper">
                <Box className="leftInfos">
                    <TimerCancel />
                    <RemarkMsgs remarkMsgs={[remarkMsg]} />
                    <ItemFieldList
                        listInfos={smsVerificationInfos}
                        fieldData={tradingData}
                    />
                </Box>
            </Box>
        </InfosLayout>
    );
}
