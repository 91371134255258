import React from 'react';

import { Box } from '@mui/material';

import { ConfirmInfos, InfosHeader } from '@components/_index';
import '@template/pages/Payment/ThreeStepsOnHoldConfirming/BuyProcess/BuyConfirming/BuyConfirming.scss';

export default function BuyConfirming() {
    return (
        <Box className="wrapper buyConfirming">
            <Box className="mainInfosWrapper">
                <InfosHeader />
                <ConfirmInfos />
            </Box>
        </Box>
    );
}
