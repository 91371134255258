import { combineReducers, configureStore } from '@reduxjs/toolkit';

import displaySettingSlice from './displaySetting/slice';
import orderSlice from './order/slice';
import paletteSlice from './palette/slice';
import verificationSlice from './verification/slice';

const reducer = combineReducers({
    order: orderSlice.reducer,
    palette: paletteSlice.reducer,
    // loading: loadingSlice.reducer
    displaySetting: displaySettingSlice.reducer,
    verification: verificationSlice.reducer
});

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export default store;
