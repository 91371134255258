import { Box, Button } from '@mui/material';
import cx from 'classnames';

import '@template/components/ItemFieldPart/ItemTag/ItemTag.scss';

import '@styles/common/ItemTag.scss';

import { VerifyIco } from '@images';

export default function ItemTag({ tagName, label, isShowIcon = true }) {
    return (
        <Box className={cx(['itemTag', label])}>
            <Button
                variant="contained"
                color="secondary"
                style={{ cursor: 'default' }}>
                {isShowIcon && <VerifyIco className="icon iconTick" />}
                {tagName}
            </Button>
        </Box>
    );
}
