import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { ItemFieldList } from '@components/_index';
import { InfosLayout } from '@layouts/_index';
import '@template/components/InfosForm/ConfirmInfos/ConfirmInfos.scss';

import useConfirmInfos from './hook';

export default function ConfirmInfos() {
    const { tradingData } = useSelector((state) => state.order);
    const { infosTitle, confirmInfos } = useConfirmInfos();
    return (
        <InfosLayout infosTitle={infosTitle} className="confirmInfos">
            <Box className="leftInfos">
                <ItemFieldList
                    listInfos={confirmInfos}
                    fieldData={tradingData}
                />
            </Box>
        </InfosLayout>
    );
}
