import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { ItemFieldList, TimerCancel } from '@components/_index';
import { InfosLayout } from '@layouts/_index';

import './KycVerificationInfos.scss';
import useKycVerificationInfos from './hook';

const KycVerificationInfos = () => {
    const { tradingData, kycVerificationInfos } = useKycVerificationInfos();

    const { kycVerifyMsg } = useSelector((state) => state.verification);

    return (
        <InfosLayout className="kycVerificationInfosWrapper">
            <Box className="infosWrapper">
                <Box className="leftInfos">
                    <TimerCancel />
                    <ItemFieldList
                        listInfos={kycVerificationInfos}
                        fieldData={tradingData}
                    />
                    <Typography className="errorMsg">{kycVerifyMsg}</Typography>
                </Box>
            </Box>
        </InfosLayout>
    );
};

export default KycVerificationInfos;
