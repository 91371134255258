import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

import { getAppSnackbarInfos } from '@template/layouts/AppSnackbar/utils';

const AppSnackbar = ({ message, severity, closeSnackbar, ...props }) => {
    const appSnackBarInfo = getAppSnackbarInfos();

    return (
        <Snackbar
            anchorOrigin={appSnackBarInfo.anchorOrigin}
            autoHideDuration={100000}
            onClose={closeSnackbar}
            TransitionComponent={appSnackBarInfo.transitionCustom}
            {...props}>
            <Alert
                onClose={closeSnackbar}
                severity={severity}
                className="snackbarWrapper">
                {message}
            </Alert>
        </Snackbar>
    );
};

export default AppSnackbar;
