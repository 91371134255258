import { useTranslation } from 'react-i18next';

import qs from 'qs';

import { CountdownButton } from '@components/_index';
import '@template/components/InfosPart/Buttons/SendSmsButton/SendSmsButton.scss';

import API from '@api/index';
import { callAPI } from '@template/utils/apiCallback';

import '@styles/common/InfosPart/Buttons/SendSmsButton.scss';

const SendSmsButton = ({
    disabled,
    payloadMore,
    onSubmitSuccess,
    onSending,
    onSubmitFailed
}) => {
    const { t } = useTranslation();
    const location = window.location;
    const queryUrl = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { token, orderNo } = queryUrl;

    const handleSendSms = () => {
        const payload = {
            token,
            orderNo,
            ...payloadMore
        };
        onSending();

        callAPI({
            apiCall: () => API.order.smsCodeSend(payload),
            successCallback: () => onSubmitSuccess(),
            failedCallBack: () => onSubmitFailed(),
            errCallBack: () => onSubmitFailed()
        });
    };

    return (
        <CountdownButton
            className="btnResend btnBeta"
            isAllowFistRenderClick={true}
            text={t('common.button.send')}
            endText={t('common.button.resend')}
            disabled={disabled}
            countDownSeconds={60000}
            handleOnClick={handleSendSms}
            isAllowOnClickReset={true}
        />
    );
};
export default SendSmsButton;
