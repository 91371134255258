import NumberFormat from 'react-number-format';

function NumFormat(props) {
    return (
        <NumberFormat
            displayType={'text'}
            thousandSeparator={true}
            {...props}
        />
    );
}

export default NumFormat;
