import { useState } from 'react';

import { useStaleFree } from '@hooks/app/useStaleFree';

import { useSnackbar } from '@utils/Providers/SnackbarProvider';

const useOpenSnackbar = () => {
    const [createSnackbar, closeSnackbar] = useSnackbar();
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    const openSnackbarWithStale = () => {
        createSnackbar({ message, severity });
    };

    const openSnackbar = useStaleFree(() => openSnackbarWithStale());

    return {
        openSnackbar: (msg, sev) => {
            setMessage(msg);
            sev && setSeverity(sev);
            openSnackbar();
        },
        closeSnackbar
    };
};

export default useOpenSnackbar;
