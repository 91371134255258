import { createContext, useContext, useRef, useState } from 'react';

import AppDialog from '@layouts/AppDialog/AppDialog';

const DialogContext = createContext();

const DialogProvider = ({ children }) => {
    const [dialogs, setDialogs] = useState([]);

    const createDialog = (option) => {
        const dialog = { ...option, open: true };
        setDialogs((dialogs) => [...dialogs, dialog]);
    };

    const closeDialog = () => {
        setDialogs((dialogs) => {
            dialogs.pop();
            return [...dialogs];
        });
    };

    const contextValue = useRef([createDialog, closeDialog]);

    return (
        <DialogContext.Provider value={contextValue.current}>
            {children}
            {dialogs.map((dialog, i) => {
                return (
                    <AppDialog key={i} closeDialog={closeDialog} {...dialog} />
                );
            })}
        </DialogContext.Provider>
    );
};

export default DialogProvider;

export const useDialog = () => useContext(DialogContext);
