import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as yup from 'yup';

import { InputFieldPhone, TextField } from '@components/_index';

import { CHIN_CHARACTER_ONLY } from '@constants/constants';
import {
    setAreaCode,
    setDisplayName,
    setWholeMobile
} from '@template/store/verification/slice';

export default function useKycVerificationInfos() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { tradingData } = useSelector((state) => state.order);

    const [allowModifyName, setAllowModifyName] = useState(true);
    const [displayNameHelperTxt, setDisplayNameHelperTxt] = useState('');
    const [displayNameInput, setDisplayNameInput] = useState('');
    const [wholeMobileInput, setWholeMobileInput] = useState(undefined);

    useEffect(() => {
        dispatch(setAreaCode(tradingData.areaCode));
        dispatch(setWholeMobile(tradingData.areaCode + tradingData.mobile));
        setWholeMobileInput(tradingData.areaCode + tradingData.mobile);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tradingData.mobile, tradingData.areaCode]);

    useEffect(() => {
        if (CHIN_CHARACTER_ONLY.test(tradingData.userName)) {
            setAllowModifyName(false);
            dispatch(setDisplayName(tradingData.userName));
            setDisplayNameInput(tradingData.userName);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tradingData.name]);

    const displayNameSchema = yup.object().shape({
        displayName: yup.string().matches(CHIN_CHARACTER_ONLY, 'chinese')
    });

    const handleMobileInput = (currWholeMobileInput, country) => {
        dispatch(setAreaCode(country.dialCode));
        dispatch(setWholeMobile(currWholeMobileInput));
        setWholeMobileInput(currWholeMobileInput);
    };

    const handleDisplayNameInput = (event) => {
        const currDisplayName = event.target.value;
        setDisplayNameInput(currDisplayName);

        displayNameSchema
            .validate({
                displayName: currDisplayName
            })
            .then(() => {
                if (
                    (currDisplayName.length <= 4 && !!currDisplayName.trim()) ||
                    currDisplayName === ''
                ) {
                    setDisplayNameHelperTxt('');

                    dispatch(setDisplayName(currDisplayName));
                } else {
                    setDisplayNameHelperTxt(
                        t('common.kycVerificationInfos.fourCharacters')
                    );
                }
            })
            .catch((err) => {
                dispatch(setDisplayName(''));
                setDisplayNameHelperTxt(
                    t('common.kycVerificationInfos.chinesTxtOnly')
                );
                return;
            });
    };

    const kycVerificationInfos = [
        {
            fieldLabel: 'personalName',
            fieldName: t('common.orderInfos.name'),
            renderFieldValue: () => {
                return (
                    <TextField
                        value={displayNameInput}
                        onChange={handleDisplayNameInput}
                        helperTxt={displayNameHelperTxt}
                        inputType="text"
                        disabled={!allowModifyName}
                    />
                );
            }
        },
        {
            fieldLabel: 'personalMobile',
            fieldName: t('common.orderInfos.mobile'),
            renderFieldValue: () => {
                return (
                    <InputFieldPhone
                        wholeMobile={wholeMobileInput}
                        onChange={handleMobileInput}
                        inputType="text"
                    />
                );
            }
        }
    ];

    return {
        tradingData,
        kycVerificationInfos
    };
}
