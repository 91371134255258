import { Box } from '@mui/material';

import { AmountInfos, QRBoardInfos } from '@components/_index';

import useAddressInfos from '@hooks/infos/useAddressInfos';

import '@template/styles/common/Paying.scss';

export default function BuyPayingManual() {
    const { infosTitle, leftInfos, rightInfos, tradingData, remarkMsgs } =
        useAddressInfos({
            isShowAddress: false,
            isHightLightUnitAmount: true,
            isShowRemarkMsgs: true
        });

    return (
        <Box className="wrapper buyPayingManual">
            <Box className="mainInfosWrapper">
                <Box className="infosGroupWrapper">
                    {/* <CompanyInfos /> */}
                    <QRBoardInfos />
                    <AmountInfos
                        infosTitle={infosTitle}
                        leftInfos={leftInfos}
                        rightInfos={rightInfos}
                        tradingData={tradingData}
                        remarkMsgs={remarkMsgs}
                        isShowTimerCancel={true}
                    />
                </Box>
            </Box>
        </Box>
    );
}
