import { FormControl, FormHelperText, OutlinedInput } from '@mui/material';
import cx from 'classnames';

import '@template/components/InputField/TextField/TextField.scss';

import '@styles/common/InputField/TextField/TextField.scss';

export default function TextField({
    label = '',
    // value = '',
    onChange,
    endAdornment,
    disabled = false,
    inputType = 'text',
    helperTxt = '',
    ...props
}) {
    return (
        <FormControl
            className={cx([
                'inputFormControl',
                label,
                disabled ? 'inputFormControlDisabled' : ''
            ])}
            variant="outlined">
            <OutlinedInput
                className="inputField"
                // value={value}
                onChange={onChange}
                endAdornment={endAdornment}
                disabled={disabled}
                type={inputType}
                {...props}
            />
            <FormHelperText className="inputFieldHelperTxt">
                {helperTxt}
            </FormHelperText>
        </FormControl>
    );
}
