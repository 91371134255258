import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { FormControl, FormHelperText } from '@mui/material';

export default function InputFieldPhone({ wholeMobile, helperTxt, onChange }) {
    return (
        <FormControl className="inputFormControl inputFormControlPhone">
            <PhoneInput
                inputClass="inputField"
                specialLabel={''}
                value={wholeMobile}
                masks={{
                    cn: '...........'
                }}
                placeholder=""
                buttonStyle={{
                    display: 'none'
                }}
                onChange={onChange}
            />
            <FormHelperText className="inputFieldHelperTxt">
                {helperTxt}
            </FormHelperText>
        </FormControl>
    );
}
