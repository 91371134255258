import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as yup from 'yup';

import { InputFieldPhone, SendSmsButton, TextField } from '@components/_index';

import {
    setAreaCode,
    setSmsCode,
    setWholeMobile
} from '@template/store/verification/slice';

export default function useSmsVerificationInfos() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { tradingData } = useSelector((state) => state.order);
    const { smsCode, smsCodeVerifyMsg, wholeMobile, areaCode } = useSelector(
        (state) => state.verification
    );
    const [mobileInputHelperTxt, setMobileInputHelperTxt] = useState('');
    const [wholeMobileInput, setWholeMobileInput] = useState(undefined);

    useEffect(() => {
        dispatch(setAreaCode(tradingData.areaCode));
        dispatch(setWholeMobile(tradingData.areaCode + tradingData.mobile));
        setWholeMobileInput(tradingData.areaCode + tradingData.mobile);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tradingData.mobile, tradingData.areaCode]);

    const smsCodeSchema = yup.object().shape({
        smsCode: yup.string().matches(/^(\s*|\d+)$/)
    });

    const handleMobileInput = (currWholeMobileInput, country) => {
        dispatch(setAreaCode(country.dialCode));
        dispatch(setWholeMobile(currWholeMobileInput));
        setWholeMobileInput(currWholeMobileInput);
    };
    const handleSmsCodeInput = (event) => {
        const currSmsCode = event.target.value;
        smsCodeSchema
            .validate({
                smsCode: currSmsCode
            })
            .then(() => {
                if (
                    (currSmsCode.length <= 6 && !!currSmsCode.trim()) ||
                    currSmsCode === ''
                ) {
                    dispatch(setSmsCode(currSmsCode));
                }
            })
            .catch((err) => {
                return;
            });
    };

    const isOnlyAreaCode =
        wholeMobileInput === undefined ||
        areaCode.length >= wholeMobileInput.length;

    const smsVerificationInfos = [
        {
            fieldLabel: 'smsVerifyMobile',
            fieldName: t('common.orderInfos.mobile'),
            renderFieldValue: () => {
                return (
                    <InputFieldPhone
                        wholeMobile={wholeMobileInput}
                        onChange={handleMobileInput}
                        helperTxt={mobileInputHelperTxt}
                        inputType="text"
                    />
                );
            }
        },
        {
            fieldLabel: 'smsCode',
            fieldName: t('common.orderInfos.smsCode'),
            renderFieldValue: () => {
                return (
                    <TextField
                        value={smsCode}
                        onChange={handleSmsCodeInput}
                        inputType="text"
                        helperTxt={smsCodeVerifyMsg}
                        endAdornment={
                            <SendSmsButton
                                disabled={
                                    !areaCode || !wholeMobile || isOnlyAreaCode
                                }
                                payloadMore={{
                                    areaCode: '+' + areaCode,
                                    mobile: wholeMobile
                                        ? wholeMobile.slice(
                                              areaCode ? areaCode.length : 0
                                          )
                                        : 0
                                }}
                                onSending={() => {
                                    setMobileInputHelperTxt('');
                                }}
                                onSubmitSuccess={() => {
                                    setMobileInputHelperTxt(
                                        t(
                                            'common.smsVerificationInfos.smsCodeSendSuccessMsg'
                                        )
                                    );
                                }}
                                onSubmitFailed={() => {
                                    setMobileInputHelperTxt(
                                        t(
                                            'common.smsVerificationInfos.smsCodeSendFailedMsg'
                                        )
                                    );
                                }}
                            />
                        }
                    />
                );
            }
        }
    ];

    const remarkMsg = t('common.smsVerificationInfos.noticeMsgVerify');

    return {
        tradingData,
        smsVerificationInfos,
        remarkMsg
    };
}
