import { Box } from '@mui/material';

import { NumFormat } from '@components/_index';
import '@template/components/ItemFieldPart/ItemNumericUnit/ItemNumericUnit.scss';

import { CURRENCIES_AVAILABLE_INFO } from '@constants/constants';

import '@styles/common/ItemFieldPart/ItemNumericUnit.scss';

export default function ItemNumericUnit({
    numeric,
    currency,
    isShowUnit = true,
    isUnitBehind = false
}) {
    const isUnitLeading =
        CURRENCIES_AVAILABLE_INFO[currency.toUpperCase()].IS_SYMBOL_LEADING;
    const symbol = CURRENCIES_AVAILABLE_INFO[currency.toUpperCase()].SYMBOL;
    const unit = isUnitBehind
        ? CURRENCIES_AVAILABLE_INFO[currency.toUpperCase()].NAME
        : symbol;
    const renderUnit = () => (
        <Box component="span" className="unit">
            {isShowUnit && unit}
        </Box>
    );
    const renderNumeric = () => (
        <NumFormat
            className="numeric"
            value={numeric}
            thousandSeparator={
                currency === CURRENCIES_AVAILABLE_INFO.VND.NAME ? '.' : ','
            }
            decimalSeparator={
                currency === CURRENCIES_AVAILABLE_INFO.VND.NAME ? ',' : '.'
            }
        />
    );

    return (
        <span className="itemNumericUnit">
            {isUnitBehind || !isUnitLeading ? (
                <>
                    {renderNumeric()} {renderUnit()}
                </>
            ) : (
                <>
                    {renderUnit()} {renderNumeric()}
                </>
            )}
        </span>
    );
}
