import { Box } from '@mui/material';

import { AmountInfos, RecipientInfos } from '@components/_index';

// import ConfirmingButton from './components/ConfirmingButton';
import useAmountInfos from '@template/hooks/infos/useAmountInfos';

import '@template/styles/common/Paying.scss';

function SellPaying() {
    const { infosTitle, leftInfos, rightInfos, tradingData, remarkMsgs } =
        useAmountInfos();
    return (
        <Box className="wrapper sellPaying">
            <Box className="mainInfosWrapper">
                <Box className="infosGroupWrapper">
                    <AmountInfos
                        infosTitle={infosTitle}
                        leftInfos={leftInfos}
                        rightInfos={rightInfos}
                        tradingData={tradingData}
                        remarkMsgs={remarkMsgs}
                        isShowTimerCancel={false}
                    />
                    {/* <CompanyInfos /> */}
                </Box>
                <RecipientInfos />
            </Box>
        </Box>
    );
}

export default SellPaying;
