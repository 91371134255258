import { Box, Button } from '@mui/material';

import { CircularProgress } from '@components/_index';
import '@template/components/InfosPart/Buttons/PendingButton/PendingButton.scss';

export default function PendingButton(props) {
    const { handleOnClick, textPending, textEnding, IconEnding } = props;
    const isPending = true;

    return (
        <Button
            variant="contained"
            disabled={isPending}
            color="primary"
            onClick={() => !isPending && handleOnClick()}
            className="btn btnPending">
            <Box className="content">
                {isPending ? (
                    <CircularProgress classNames={['iconAddition']} />
                ) : (
                    <IconEnding className="icon iconAddition TickIco" />
                )}
                <Box className="text">
                    {isPending ? textPending : textEnding}
                </Box>
            </Box>
        </Button>
    );
}
