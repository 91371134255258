import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import useOpenDialog from '@hooks/app/useOpenDialog';
import useCloseDialog from '@template/hooks/app/useCloseDialog';
import { cancelOrder } from '@template/store/order/slice';

import DialogContent from './DialogContent';
import { CancelIco } from '@images';

const useOpenDialogPayActions = (renderContentText) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { legalCurrency, transactionType } = useParams();
    const { closeDialog } = useCloseDialog();

    const handleConfirm = () => {
        closeDialog();
        dispatch(cancelOrder(legalCurrency, transactionType));
    };
    const { openDialog } = useOpenDialog({
        // title: t('securitySettings.dialogPayActions.title'),
        name: 'dialogPayActions',
        children: (
            <DialogContent
                title={t('common.dialog.cancelDialog.title')}
                TitleIco={CancelIco}
                onConfirm={handleConfirm}
                btnConfirmText={t('common.button.confirm')}
                btnCancelText={t('common.button.holdOn')}
                renderContentText={renderContentText}
            />
        )
    });

    return {
        openDialogPayActions: openDialog
    };
};

export default useOpenDialogPayActions;
