import { Box, Button, DialogActions, DialogContent } from '@mui/material';

import '@template/components/Dialogs/DialogActions/DialogActions.scss';

import useCloseDialog from '@template/hooks/app/useCloseDialog';

import '@styles/common/DialogActions.scss';

import { CloseIco, TickIco } from '@images';

export default function DialogContentPayCancel({
    title,
    TitleIco,
    onConfirm,
    btnConfirmText,
    btnCancelText,
    renderContentText,
    IconCancel = CloseIco,
    IconConfirm = TickIco
}) {
    const { closeDialog } = useCloseDialog();

    return (
        <Box className="actionsDialogContent">
            <DialogContent className="contentWrapper">
                <Box className="headline">
                    <TitleIco className="iconTitle" />
                    <Box className="title">{title}</Box>
                </Box>
                <Box className="mainContent">{renderContentText()}</Box>
            </DialogContent>
            <DialogActions className="dialogActionGroup">
                <Button
                    className="btn btnCancel"
                    variant="outlined"
                    color="secondary"
                    onClick={() => closeDialog()}>
                    <Box className="content">
                        {IconCancel && (
                            <IconCancel className="icon iconAddition closeIco" />
                        )}
                        <Box className="text">{btnCancelText}</Box>
                    </Box>
                </Button>
                <Button
                    className="btn btnConfirm"
                    variant="contained"
                    color="primary"
                    onClick={onConfirm}>
                    <Box className="content">
                        {IconConfirm && (
                            <IconConfirm className="icon iconAddition confirmIco" />
                        )}
                        <Box className="text">{btnConfirmText}</Box>
                    </Box>
                </Button>
            </DialogActions>
        </Box>
    );
}
