import { createSlice } from '@reduxjs/toolkit';

import { FLEX_LAYOUT } from '@constants/styleLayout';
import { initialSettingState } from '@template/store/setting';

const commonInitialSettingState = {
    // loadingType: LOADING_TYPE.FULL_PAGE, // set loading type as fullpage or overlay
    isShowBuySellPrices: false, // show buy sell prices or just one in AmountInfos
    isPayMethodUnderTraderName: true, // pay method field location inside RecipientInfos
    isBtnGroupAsideMainComponent: false, // button group component location inside PayProcess
    itemFieldLeftFlexLayout: FLEX_LAYOUT.FLEX_START, // layout of every ItemField, flex start or space between,
    isShowBuyAddressInfosCountField: false, // show count field in addressInfo in (USDT) buy order
    ...initialSettingState
};

const displaySettingSlice = createSlice({
    name: 'displaySetting',
    initialState: commonInitialSettingState
});

export default displaySettingSlice;
