import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import QRCode from 'qrcode.react';

import '@template/components/InfosPart/QRCodeBoard/QRCodeBoard.scss';

export default function QRCodeBoard({ fieldValue }) {
    const { imgOutline } = useSelector((state) => state.palette);

    return (
        <Box className="qrCodeBoardWrapper">
            <QRCode
                renderAs="svg"
                className="qrCodeImg"
                value={fieldValue ?? ''}
                fgColor={imgOutline || '#000000'}
                size={200}
            />
        </Box>
    );
}
