import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ItemNumericUnit from '@components/ItemFieldPart/ItemNumericUnit/ItemNumericUnit';

import { TRANSACTION_TYPE } from '@constants/constants';

export default function useAddressInfos({
    isShowAddress,
    isHightLightUnitAmount,
    isShowRemarkMsgs
}) {
    const { t } = useTranslation();
    const { tradingData } = useSelector((state) => state.order);
    const { isShowBuyAddressInfosCountField } = useSelector(
        (state) => state.displaySetting
    );
    const { transactionType } = useParams();

    const infosTitle =
        transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
            ? t('buyProcess.addressInfosTitle')
            : t('sellProcess.addressInfosTitle');

    let leftInfos = [
        {
            fieldLabel: 'orderNo',
            fieldName: t('common.orderInfos.orderNo'),
            isValueBold: true
        }
    ];
    let rightInfos = [];
    let remarkMsgs = [];

    const countInfo = {
        fieldLabel: 'unitCount',
        fieldName: t('common.orderInfos.count'),
        fieldValue: tradingData.currency + ' ' + tradingData.count,
        renderFieldValue: () =>
            ItemNumericUnit({
                numeric: tradingData.count,
                currency: tradingData.currency,
                isShowUnit: true
            }),
        isValueBold: true,
        isShowCopyBtn: isHightLightUnitAmount
    };

    if (isShowAddress) {
        leftInfos = [
            ...leftInfos,
            {
                fieldLabel: 'toAddress',
                fieldName:
                    transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
                        ? t('buyProcess.toAddress')
                        : t('sellProcess.toAddress'),
                isValueBold: true,
                isShowCopyBtn: true
            }
        ];
    }

    if (isShowRemarkMsgs) {
        remarkMsgs =
            transactionType.toUpperCase() === TRANSACTION_TYPE.BUY
                ? [t('buyProcess.paying.noticeMsgAddress')]
                : [t('sellProcess.paying.noticeMsgAddress')];
    }

    if (
        transactionType.toUpperCase() === TRANSACTION_TYPE.SELL ||
        (isShowBuyAddressInfosCountField &&
            transactionType.toUpperCase() === TRANSACTION_TYPE.BUY)
    ) {
        if (isHightLightUnitAmount) {
            rightInfos = [...rightInfos, ...[countInfo]];
        } else {
            leftInfos = [...leftInfos, ...[countInfo]];
        }
    }

    return {
        leftInfos,
        rightInfos,
        tradingData,
        infosTitle,
        remarkMsgs,
        isShowBuyAddressInfosCountField
    };
}
