import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';
import cx from 'classnames';

import '@styles/common/layouts/InfosLayout/InfosLayout.scss';

import '@template/layouts/InfosLayout/InfosLayout.scss';

function InfosLayout({ infosTitle, className, children }) {
    const { itemFieldLeftFlexLayout } = useSelector(
        (state) => state.displaySetting
    );
    return (
        <Box
            className={cx([
                'orderInfos',
                `${itemFieldLeftFlexLayout}ItemFieldLeftOrderInfos`,
                className
            ])}>
            {infosTitle && (
                <Typography component="h3" className="infosTitle">
                    <span className="infosTitleTxt">{infosTitle}</span>
                </Typography>
            )}
            {children}
        </Box>
    );
}

export default InfosLayout;
