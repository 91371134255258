import { Provider } from 'react-redux';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import store from '@store/configureStore';

import { theme } from '@template/styles/theme';

import DialogProvider from './DialogProvider';
import SnackbarProvider from './SnackbarProvider';

const AppProvider = ({ children }) => {
    return (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    {/* <LoadingProvider> */}
                    <SnackbarProvider>
                        <DialogProvider>{children}</DialogProvider>
                    </SnackbarProvider>
                    {/* </LoadingProvider> */}
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
};

export default AppProvider;
