import { AlipayIco, BankPayIco, PaymentIco } from '@images';

export const PAY_TYPE_INFO = {
    WALLET: {
        NAME: 'WALLET',
        PayIconComponent: PaymentIco
    },
    BANKPAY: {
        NAME: 'BANKPAY',
        PayIconComponent: BankPayIco
    },
    ALIPAY: {
        NAME: 'ALIPAY',
        PayIconComponent: AlipayIco
    }
};
