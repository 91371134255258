export const callAPI = async ({
    apiCall,
    successCallback,
    failedCallBack,
    errCallBack,
    finalCallBack
}) => {
    await apiCall()
        .then((res) => {
            if (res.data.code === 0) {
                successCallback(res);
                return;
            }
            failedCallBack && failedCallBack(res);
        })
        .catch((err) => {
            errCallBack && errCallBack(err);
        })
        .finally(() => {
            finalCallBack && finalCallBack();
        });
};
