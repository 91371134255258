import Pages from '@template/pages/_index';

export const TRANSACTION_TYPE = {
    BUY: 'BUY',
    SELL: 'SELL'
};

export const CURRENCIES_AVAILABLE_INFO = {
    USDT: {
        NAME: 'USDT',
        SYMBOL: 'USDT',
        IS_SYMBOL_LEADING: false,
        PageComponents: {
            [TRANSACTION_TYPE.BUY]: Pages.USDTBuy,
            [TRANSACTION_TYPE.SELL]: Pages.USDTSell
        }
    },
    VND: {
        NAME: 'VND',
        SYMBOL: '₫',
        IS_SYMBOL_LEADING: true,
        PageComponents: {
            [TRANSACTION_TYPE.BUY]: Pages.VNDBuy,
            [TRANSACTION_TYPE.SELL]: Pages.VNDSell
        }
    },
    THB: {
        NAME: 'THB',
        SYMBOL: '฿',
        IS_SYMBOL_LEADING: true,
        PageComponents: {
            [TRANSACTION_TYPE.BUY]: Pages.THBBuy,
            [TRANSACTION_TYPE.SELL]: Pages.THBSell
        }
    },
    CNY: {
        NAME: 'CNY',
        SYMBOL: '¥',
        IS_SYMBOL_LEADING: true,
        PageComponents: {
            [TRANSACTION_TYPE.BUY]: Pages.CNYBuy,
            [TRANSACTION_TYPE.SELL]: Pages.CNYSell
        }
    }
};

export const PROCESS_NAMES = {
    PAYING: 'PAYING',
    PAYING_MANUAL: 'PAYING_MANUAL',
    PROCESSING: 'PROCESSING',
    CONFIRMING: 'CONFIRMING',
    COMPLETED: 'COMPLETED'
};

export const RESULT_STATUS = {
    ORDER_FINISH: 'ORDER_FINISH',
    HANDLE_FINISH: 'HANDLE_FINISH',
    ORDER_CANCEL: 'ORDER_CANCEL',
    ORDER_ERROR: 'ORDER_ERROR',
    PROCESSING: 'PROCESSING',
    PAY_NO: 'PAY_NO',
    PAY_FINISH: 'PAY_FINISH'
};

export const CHIN_CHARACTER_ONLY =
    /^[\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF]{1,20}$/i;
