import { Box } from '@mui/material';

import { AmountInfos } from '@components/_index';

import useAddressInfos from '@template/hooks/infos/useAddressInfos';

import '@template/styles/common/Paying.scss';

function SellPaying() {
    const { infosTitle, leftInfos, rightInfos, tradingData, remarkMsgs } =
        useAddressInfos({
            isShowAddress: true,
            isHightLightUnitAmount: true,
            isShowRemarkMsgs: true
        });
    return (
        <Box className="wrapper sellPayingTwoStepSimple">
            <Box className="mainInfosWrapper">
                <Box className="infosGroupWrapper infosGroupWrapperAlpha">
                    {/* <CompanyInfos /> */}
                    <AmountInfos
                        infosTitle={infosTitle}
                        leftInfos={leftInfos}
                        rightInfos={rightInfos}
                        tradingData={tradingData}
                        remarkMsgs={remarkMsgs}
                        isShowTimerCancel={true}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default SellPaying;
