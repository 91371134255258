import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';

import useOpenDialogPayActions from '@components/Dialogs/DialogActions/hook';

import { CloseIco } from '@images';

const CancelPopButton = ({ renderContentText }) => {
    const { t } = useTranslation();
    const { openDialogPayActions } = useOpenDialogPayActions(renderContentText);

    return (
        <Button
            className="btn btnCancel"
            variant="outlined"
            color="secondary"
            onClick={() => openDialogPayActions()}>
            <Box className="content">
                <CloseIco className="icon iconAddition closeIco" />
                <Box className="text">{t('common.button.cancel')}</Box>
            </Box>
        </Button>
    );
};
export default CancelPopButton;
