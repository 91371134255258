const useStepper = (stepNames, currStep) => {
    const currStepIndex = stepNames.findIndex(
        (stepName) => currStep === stepName
    );

    const snakeToCamel = (str) => {
        return str
            .toLowerCase()
            .replace(/([_][a-z])/g, (group) =>
                group.toUpperCase().replace('_', '')
            );
    };

    const camelStepNames = stepNames.map((stepName) => snakeToCamel(stepName));

    return {
        currStepIndex,
        camelStepNames
    };
};

export default useStepper;
