import { Box, LinearProgress } from '@mui/material';

import './FullPageLoading.scss';

export default function FullPageLoading() {
    return (
        <Box className="fullPageLoadingWrapper">
            <LinearProgress />
        </Box>
    );
}
