import { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { FullPageLoading } from '@components/_index';
import Error from '@template/pages/Error/Error';
import ResolvePayment from '@template/pages/ResolvePayment/ResolvePayment';

export default function AppRouter() {
    return (
        <BrowserRouter>
            <Suspense fallback={<FullPageLoading />}>
                <Switch>
                    <Route exact path="/:legalCurrency/:transactionType">
                        <ResolvePayment />
                    </Route>
                    <Route path="/">
                        <Error />
                    </Route>
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}
