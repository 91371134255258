export const FLEX_LAYOUT = {
    SPACE_BETWEEN: 'spaceBetween',
    FLEX_START: 'flexStart'
};

export const SPACING = {
    X_SMALL: 'xSmall',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
};
