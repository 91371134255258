import { createContext, useContext, useRef, useState } from 'react';

import AppSnackbar from '@layouts/AppSnackbar/AppSnackbar';

const SnackbarContext = createContext();

const SnackbarProvider = ({ children }) => {
    const [snackbars, setSnackbars] = useState([]);

    const createSnackbar = (option) => {
        const snackbar = { ...option, open: true };
        setSnackbars((snackbars) => [...snackbars, snackbar]);
    };

    const closeSnackbar = () => {
        setSnackbars((snackbars) => {
            snackbars.pop();
            return [...snackbars];
        });
    };

    const contextValue = useRef([createSnackbar, closeSnackbar]);

    return (
        <SnackbarContext.Provider value={contextValue.current}>
            {children}
            {snackbars.map((snackbar, i) => {
                return (
                    <AppSnackbar
                        key={i}
                        closeSnackbar={closeSnackbar}
                        {...snackbar}
                    />
                );
            })}
        </SnackbarContext.Provider>
    );
};

export default SnackbarProvider;

export const useSnackbar = () => useContext(SnackbarContext);
