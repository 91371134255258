import { createSlice } from '@reduxjs/toolkit';

const initialOrderState = {
    displayName: '',
    areaCode: '',
    wholeMobile: '',
    smsCode: '',
    smsCodeVerifyMsg: '',
    kycVerifyMsg: ''
};

const verificationSlice = createSlice({
    name: 'verification',
    initialState: {
        ...initialOrderState
    },
    reducers: {
        setDisplayName: (state, action) => {
            state.displayName = action.payload;
        },
        setSmsCode: (state, action) => {
            state.smsCode = action.payload;
        },
        setAreaCode: (state, action) => {
            state.areaCode = action.payload;
        },
        setWholeMobile: (state, action) => {
            state.wholeMobile = action.payload;
        },
        setSmsCodeVerifyMsg: (state, action) => {
            state.smsCodeVerifyMsg = action.payload;
        },
        setKycVerifyMsg: (state, action) => {
            state.kycVerifyMsg = action.payload;
        }
    }
});

export default verificationSlice;

export const {
    setDisplayName,
    setSmsCode,
    setSmsCodeVerifyMsg,
    setAreaCode,
    setWholeMobile,
    setKycVerifyMsg
} = verificationSlice.actions;
