import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import moment from 'moment';

import { Timer } from '@components/_index';

import { setIsCancelTimerFinished } from '@store/order/slice';

export default function TimerCancel() {
    const dispatch = useDispatch();
    const { tradingData, isShowCancelTimer } = useSelector(
        (state) => state.order
    );

    const currentTimeAtServer = tradingData.systemTime;
    const currentTimeAtDevice = Date.now();
    const timeOffset = currentTimeAtServer - currentTimeAtDevice;

    const handleTimerFinished = () => dispatch(setIsCancelTimerFinished(true));
    return (
        <>
            {isShowCancelTimer && (
                <Box className="timerCancelWrapper">
                    <Timer
                        date={
                            moment(tradingData.updatedTime).valueOf() +
                            tradingData.availableTime * 1000
                        }
                        onComplete={handleTimerFinished}
                        onMount={(date) =>
                            date.completed && handleTimerFinished()
                        }
                        now={() => Date.now() + timeOffset}
                    />
                </Box>
            )}
        </>
    );
}
