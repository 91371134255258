import AppProvider from '@utils/Providers/AppProvider';

import '@styles/common/InputField/common.scss';
import '@styles/common/common.scss';

import '@template/styles/common/common.scss';

import AppRoute from './routes';

function App() {
    return (
        <AppProvider>
            <AppRoute />
        </AppProvider>
    );
}

export default App;
