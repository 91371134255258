import cx from 'classnames';

import '@template/components/LogoBrand/LogoBrand.scss';

const LogoBrand = ({ className }) => {
    return (
        <img
            className={cx(['logoBrand', className])}
            src={process.env.PUBLIC_URL + process.env.BRAND_LOGO_RELATIVE_PATH}
            alt="band"
        />
    );
};

export default LogoBrand;
